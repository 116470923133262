import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandIcon from "@mui/icons-material/ExpandLess";
import CollapseIcon from "@mui/icons-material/ExpandMore";
import { Button, FormInput, FormMDEditor } from "components";
import { getCustomIsDirty, useEnhancedFormContext } from "hooks/form";
import { useContext, useEffect, useState } from "react";
import { Controller, UseFieldArrayRemove, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IEditedVaultDescription } from "types";
import { VaultEditorFormContext } from "../../../store";
import { StyledVulnerabilitySeverityForm } from "./styles";

type VulnerabilitySeverityFormProps = {
  index: number;
  severitiesCount: number;
  remove: UseFieldArrayRemove;
};

export default function VulnerabilitySeverityForm({ index, remove, severitiesCount }: VulnerabilitySeverityFormProps) {
  const { t } = useTranslation();

  const { register, control, getValues, setValue } = useEnhancedFormContext<IEditedVaultDescription>();

  const { allFormDisabled } = useContext(VaultEditorFormContext);

  const severities = useWatch({ control, name: "vulnerability-severities-spec.severities" });
  const severityName = severities[index].name;
  const vaultType = useWatch({ control, name: "project-metadata.type" });
  const isAuditCompetition = vaultType === "audit";
  const [isExpanded, setIsExpanded] = useState(!severityName);

  // Update severities options for usage in contracts covered
  useEffect(() => {
    const sevOpts = severities.map((sev) => ({ label: sev.name, value: sev.id }));
    const currentSevOpts = getValues("severitiesOptions");

    if (JSON.stringify(sevOpts) !== JSON.stringify(currentSevOpts)) {
      setValue("severitiesOptions", sevOpts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [severities]);

  const isV1 = useWatch({ control, name: "version" }) === "v1";

  return (
    <StyledVulnerabilitySeverityForm expanded={isExpanded} showNftInfo={false}>
      <div className="header" onClick={() => setIsExpanded((prev) => !prev)}>
        {isExpanded ? <CollapseIcon fontSize="large" /> : <ExpandIcon fontSize="large" />}
        <span>{severityName}</span>
      </div>

      <div className="severity">
        <div className="content">
          {isAuditCompetition && (
            <FormInput
              {...register(`vulnerability-severities-spec.severities.${index}.decryptSubmissions`)}
              type="toggle"
              disabled={allFormDisabled}
              label={t("VaultEditor.decryptSubmissions")}
              colorable
            />
          )}
          <div className="subcontent">
            <div className="w-40">
              <FormInput
                {...register(`vulnerability-severities-spec.severities.${index}.name`)}
                disabled={allFormDisabled}
                label={t("VaultEditor.severity-name")}
                colorable
                placeholder={t("VaultEditor.severity-name-placeholder")}
              />
            </div>
            {isV1 ? (
              <div className="w-40">
                <FormInput
                  {...register(`vulnerability-severities-spec.severities.${index}.index`, { valueAsNumber: true })}
                  disabled={allFormDisabled}
                  label={t("VaultEditor.severity-index")}
                  colorable
                  placeholder={t("VaultEditor.severity-index-placeholder")}
                />
              </div>
            ) : (
              <div className="numbers-container">
                <div className="percentage-container">
                  <FormInput
                    {...register(`vulnerability-severities-spec.severities.${index}.percentage`, { valueAsNumber: true })}
                    disabled={allFormDisabled}
                    label={t("VaultEditor.percentage-bounty")}
                    colorable
                    type="number"
                    min={0}
                    max={100}
                    noMargin
                    placeholder={t("VaultEditor.percentage-bounty-placeholder")}
                  />
                </div>

                <FormInput
                  {...register(`vulnerability-severities-spec.severities.${index}.capAmount`, { valueAsNumber: true })}
                  disabled={allFormDisabled}
                  label={t("VaultEditor.maxAmountPerBeneficiary")}
                  colorable
                  type="number"
                  min={0}
                  noMargin
                  placeholder={t("VaultEditor.maxAmountPerBeneficiary-placeholder")}
                />
              </div>
            )}
          </div>

          <Controller
            control={control}
            name={`vulnerability-severities-spec.severities.${index}.description`}
            render={({ field, fieldState: { error }, formState: { dirtyFields, defaultValues } }) => (
              <FormMDEditor
                disabled={allFormDisabled}
                isDirty={getCustomIsDirty<IEditedVaultDescription>(field.name, dirtyFields, defaultValues)}
                error={error}
                initialState="edit"
                colorable
                {...field}
              />
            )}
          />
        </div>

        {!allFormDisabled && severitiesCount > 1 && (
          <div className="controller-buttons">
            <Button styleType="outlined" filledColor="secondary" onClick={() => remove(index)}>
              <DeleteIcon className="mr-1" />
              <span>{t("removeSeverity")}</span>
            </Button>
          </div>
        )}
      </div>
    </StyledVulnerabilitySeverityForm>
  );
}
