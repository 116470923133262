"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExecutePayoutSafeTransaction = exports.createNewSplitPayoutBeneficiary = exports.createNewPayoutData = void 0;
const protocol_kit_1 = __importStar(require("@safe-global/protocol-kit"));
const ethers_1 = require("ethers");
const abis_1 = require("../abis");
const chains_1 = require("./../config/chains");
function truncate(num, fixed) {
    var _a, _b;
    const regex = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return (_b = (_a = num.toString().match(regex)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : num.toString();
}
const createNewPayoutData = (type) => {
    if (type === "single") {
        return {
            type,
            beneficiary: "",
            title: "",
            severity: "",
            percentageToPay: "",
            severityBountyIndex: "",
            explanation: "",
            nftUrl: "",
            additionalInfo: "Submission tx: \n\nSubmission link: \n\nDecrypted submission: ",
        };
    }
    else {
        return {
            type,
            title: "",
            explanation: "",
            additionalInfo: "",
            beneficiaries: [(0, exports.createNewSplitPayoutBeneficiary)()],
        };
    }
};
exports.createNewPayoutData = createNewPayoutData;
const createNewSplitPayoutBeneficiary = () => {
    return {
        beneficiary: "",
        severity: "",
        percentageOfPayout: "",
        nftUrl: "",
    };
};
exports.createNewSplitPayoutBeneficiary = createNewSplitPayoutBeneficiary;
const getExecutePayoutSafeTransaction = async (provider, committee, payout) => {
    const ethAdapter = new protocol_kit_1.EthersAdapter({ ethers: ethers_1.ethers, signerOrProvider: provider });
    const safeSdk = await protocol_kit_1.default.create({ ethAdapter: ethAdapter, safeAddress: committee });
    const vaultInfo = payout.vaultInfo;
    const percentageToPay = Math.round(Number(payout.payoutData.percentageToPay) * 100);
    if (payout.payoutData.type === "single") {
        // Single payout: only one TX calling the vault contract
        const contractAddress = vaultInfo.version === "v1" ? vaultInfo.master : vaultInfo.address;
        const payoutData = payout.payoutData;
        let encodedExecPayoutData = "";
        if (vaultInfo.version === "v1") {
            const contractInterface = new ethers_1.ethers.utils.Interface(abis_1.HATSVaultV1_abi);
            encodedExecPayoutData = contractInterface.encodeFunctionData("pendingApprovalClaim", [
                Number(vaultInfo.pid),
                payoutData.beneficiary,
                Number(payoutData.severityBountyIndex),
            ]);
        }
        else {
            const contractInterface = new ethers_1.ethers.utils.Interface(abis_1.HATSVaultV2_abi);
            encodedExecPayoutData = contractInterface.encodeFunctionData("submitClaim", [
                payoutData.beneficiary,
                percentageToPay,
                payout.payoutDescriptionHash,
            ]);
        }
        const safeTransaction = await safeSdk.createTransaction({
            safeTransactionData: {
                to: contractAddress,
                data: encodedExecPayoutData,
                value: "0",
            },
        });
        const safeTransactionHash = await safeSdk.getTransactionHash(safeTransaction);
        return { tx: safeTransaction, txHash: safeTransactionHash };
    }
    else {
        // Only works with v2 vaults
        // Split payout: two TXs with a batch on safe. One to create the payment splitter, and the other to execute the payout.
        // First TX: create payment splitter (this will be the beneficiary of the vault)
        // Second TX: execute payout
        if (vaultInfo.version === "v1")
            throw new Error("Split payouts are only supported for v2 vaults");
        const paymentSplitterFactoryAddress = chains_1.ChainsConfig[Number(vaultInfo.chainId)].paymentSplitterFactory;
        if (!paymentSplitterFactoryAddress)
            throw new Error("Payment splitter factory address not found");
        const vaultContract = {
            address: vaultInfo.address,
            interface: new ethers_1.ethers.utils.Interface(abis_1.HATSVaultV2_abi),
        };
        const paymentSplitterFactoryContract = {
            address: paymentSplitterFactoryAddress,
            interface: new ethers_1.ethers.utils.Interface(abis_1.HATPaymentSplitterFactory_abi),
        };
        const payoutData = payout.payoutData;
        // Join same beneficiaries and sum percentages
        const beneficiariesJointPercentage = payoutData.beneficiaries.reduce((acc, beneficiary) => {
            const existingBeneficiary = acc.find((b) => b.beneficiary === beneficiary.beneficiary);
            if (existingBeneficiary) {
                existingBeneficiary.percentageOfPayout = truncate(+truncate(+existingBeneficiary.percentageOfPayout, 4) + +truncate(+beneficiary.percentageOfPayout, 4), 4);
            }
            else {
                acc.push(beneficiary);
            }
            return acc;
        }, []);
        // Payout payment splitter creation TX
        const encodedPaymentSplitterCreation = paymentSplitterFactoryContract.interface.encodeFunctionData("createHATPaymentSplitter", [
            beneficiariesJointPercentage.map((beneficiary) => beneficiary.beneficiary),
            beneficiariesJointPercentage.map((beneficiary) => ethers_1.BigNumber.from(Math.round(Number(beneficiary.percentageOfPayout) * 10 ** 10))),
        ]);
        // Payout execution TX
        const encodedExecutePayout = vaultContract.interface.encodeFunctionData("submitClaim", [
            payoutData.paymentSplitterBeneficiary,
            percentageToPay,
            payout.payoutDescriptionHash,
        ]);
        const safeTransaction = await safeSdk.createTransaction({
            safeTransactionData: [
                {
                    to: paymentSplitterFactoryAddress,
                    data: encodedPaymentSplitterCreation,
                    value: "0",
                },
                {
                    to: vaultContract.address,
                    data: encodedExecutePayout,
                    value: "0",
                },
            ],
        });
        const safeTransactionHash = await safeSdk.getTransactionHash(safeTransaction);
        return { tx: safeTransaction, txHash: safeTransactionHash };
    }
};
exports.getExecutePayoutSafeTransaction = getExecutePayoutSafeTransaction;
