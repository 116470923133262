"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsureDao_abi = void 0;
exports.InsureDao_abi = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "owner", type: "address" },
            { indexed: true, internalType: "address", name: "spender", type: "address" },
            { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "address", name: "contributor", type: "address" },
            { indexed: false, internalType: "uint256[]", name: "ids", type: "uint256[]" },
        ],
        name: "BountyPaid",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "uint256", name: "pending", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "payoutNumerator", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "payoutDenominator", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "incidentTimestamp", type: "uint256" },
            { indexed: false, internalType: "bytes32", name: "merkleRoot", type: "bytes32" },
            { indexed: false, internalType: "string", name: "rawdata", type: "string" },
            { indexed: false, internalType: "string", name: "memo", type: "string" },
        ],
        name: "CoverApplied",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "withdrawer", type: "address" },
            { indexed: false, internalType: "uint256", name: "credit", type: "uint256" },
        ],
        name: "CreditDecrease",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "depositor", type: "address" },
            { indexed: false, internalType: "uint256", name: "credit", type: "uint256" },
        ],
        name: "CreditIncrease",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "depositor", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "mint", type: "uint256" },
        ],
        name: "Deposit",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "bytes32", name: "target", type: "bytes32" },
            { indexed: false, internalType: "uint256", name: "startTime", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "endTime", type: "uint256" },
            { indexed: false, internalType: "address", name: "insured", type: "address" },
            { indexed: false, internalType: "address", name: "agent", type: "address" },
            { indexed: false, internalType: "uint256", name: "premium", type: "uint256" },
        ],
        name: "Insured",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "enum IPoolTemplate.MarketStatus", name: "statusValue", type: "uint8" }],
        name: "MarketStatusChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "string", name: "metadata", type: "string" }],
        name: "MetadataChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "bool", name: "paused", type: "bool" }],
        name: "Paused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
            { indexed: false, internalType: "address", name: "insured", type: "address" },
            { indexed: false, internalType: "bytes32", name: "target", type: "bytes32" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "payout", type: "uint256" },
        ],
        name: "Redeemed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "from", type: "address" },
            { indexed: true, internalType: "address", name: "to", type: "address" },
            { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "Unlocked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "withdrawer", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "retVal", type: "uint256" },
        ],
        name: "Withdraw",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "withdrawer", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "unlockTime", type: "uint256" },
        ],
        name: "WithdrawRequested",
        type: "event",
    },
    {
        inputs: [],
        name: "allInsuranceCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_credit", type: "uint256" }],
        name: "allocateCredit",
        outputs: [{ internalType: "uint256", name: "_pending", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_amount", type: "uint256" },
            { internalType: "address", name: "_contributor", type: "address" },
            { internalType: "uint256[]", name: "_ids", type: "uint256[]" },
        ],
        name: "applyBounty",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_pending", type: "uint256" },
            { internalType: "uint256", name: "_payoutNumerator", type: "uint256" },
            { internalType: "uint256", name: "_payoutDenominator", type: "uint256" },
            { internalType: "uint256", name: "_incidentTimestamp", type: "uint256" },
            { internalType: "bytes32", name: "_merkleRoot", type: "bytes32" },
            { internalType: "string", name: "_rawdata", type: "string" },
            { internalType: "string", name: "_memo", type: "string" },
        ],
        name: "applyCover",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "attributionDebt",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "availableBalance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "string", name: "_metadata", type: "string" }],
        name: "changeMetadata",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "subtractedValue", type: "uint256" },
        ],
        name: "decreaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "deposit",
        outputs: [{ internalType: "uint256", name: "_mintAmount", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_amount", type: "uint256" },
            { internalType: "uint256", name: "_span", type: "uint256" },
        ],
        name: "getPremium",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_token", type: "address" }],
        name: "getTokenMetadata",
        outputs: [
            { internalType: "string", name: "_name", type: "string" },
            { internalType: "string", name: "_symbol", type: "string" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "incident",
        outputs: [
            { internalType: "uint256", name: "payoutNumerator", type: "uint256" },
            { internalType: "uint256", name: "payoutDenominator", type: "uint256" },
            { internalType: "uint256", name: "incidentTimestamp", type: "uint256" },
            { internalType: "bytes32", name: "merkleRoot", type: "bytes32" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
        ],
        name: "increaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "indexList",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "indices",
        outputs: [
            { internalType: "uint256", name: "credit", type: "uint256" },
            { internalType: "uint256", name: "rewardDebt", type: "uint256" },
            { internalType: "uint256", name: "index", type: "uint256" },
            { internalType: "bool", name: "exist", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_depositor", type: "address" },
            { internalType: "string", name: "_metaData", type: "string" },
            { internalType: "uint256[]", name: "_conditions", type: "uint256[]" },
            { internalType: "address[]", name: "_references", type: "address[]" },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "initialized",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "insurances",
        outputs: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint48", name: "startTime", type: "uint48" },
            { internalType: "uint48", name: "endTime", type: "uint48" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "bytes32", name: "target", type: "bytes32" },
            { internalType: "address", name: "insured", type: "address" },
            { internalType: "address", name: "agent", type: "address" },
            { internalType: "bool", name: "status", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_amount", type: "uint256" },
            { internalType: "uint256", name: "_maxCost", type: "uint256" },
            { internalType: "uint256", name: "_span", type: "uint256" },
            { internalType: "bytes32", name: "_target", type: "bytes32" },
            { internalType: "address", name: "_for", type: "address" },
            { internalType: "address", name: "_agent", type: "address" },
        ],
        name: "insure",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lockedAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "marketStatus",
        outputs: [{ internalType: "enum IPoolTemplate.MarketStatus", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "metadata",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "originalLiquidity",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_index", type: "address" }],
        name: "pairValues",
        outputs: [
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "uint256", name: "", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "parameters",
        outputs: [{ internalType: "contract IParameters", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pendingEnd",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_index", type: "address" }],
        name: "pendingPremium",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "rate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_id", type: "uint256" },
            { internalType: "uint256", name: "_loss", type: "uint256" },
            { internalType: "bytes32[]", name: "_merkleProof", type: "bytes32[]" },
        ],
        name: "redeem",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_index", type: "uint256" }],
        name: "registerIndex",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "registry",
        outputs: [{ internalType: "contract IRegistry", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "requestWithdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { inputs: [], name: "resume", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [],
        name: "rewardPerCredit",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
        name: "setPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalCredit",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalLiquidity",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "sender", type: "address" },
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "unlock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256[]", name: "_ids", type: "uint256[]" }],
        name: "unlockBatch",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "utilizationRate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_owner", type: "address" }],
        name: "valueOfUnderlying",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "vault",
        outputs: [{ internalType: "contract IVault", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "withdraw",
        outputs: [{ internalType: "uint256", name: "_retVal", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_credit", type: "uint256" }],
        name: "withdrawCredit",
        outputs: [{ internalType: "uint256", name: "_pending", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "withdrawalReq",
        outputs: [
            { internalType: "uint256", name: "timestamp", type: "uint256" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_value", type: "uint256" }],
        name: "worth",
        outputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
];
