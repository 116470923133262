"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allowedAttributesMarkdown = exports.allowedElementsMarkdown = void 0;
exports.allowedElementsMarkdown = [
    "a",
    "article",
    "b",
    "blockquote",
    "br",
    "caption",
    "code",
    "del",
    "details",
    "div",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "i",
    "img",
    "ins",
    "kbd",
    "li",
    "main",
    "ol",
    "p",
    "pre",
    "section",
    "span",
    "strike",
    "strong",
    "sub",
    "summary",
    "sup",
    "table",
    "tbody",
    "td",
    "th",
    "thead",
    "tr",
    "u",
    "ul",
];
exports.allowedAttributesMarkdown = {
    a: ["href", "name", "class"],
    img: ["src", "alt", "class"],
};
