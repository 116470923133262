"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.editedFormToCreateVaultOnChainCall = exports.fixObject = exports.editedFormToDescription = exports.descriptionToEditedForm = exports.severitiesToContractsCoveredForm = exports.createNewVaultDescription = exports.getDefaultVaultParameters = exports.createNewVulnerabilitySeverity = exports.createNewCoveredContract = exports.createNewCommitteeMember = exports.HATS_REWARD_SPLIT = exports.HATS_GOV_SPLIT = exports.COMMITTEE_CONTROLLED_SPLIT = exports.CODE_LANGUAGES = exports.DEFAULT_TOOLING_STEPS = exports.DEFAULT_OUT_OF_SCOPE = void 0;
const uuid_1 = require("uuid");
const severities_1 = require("../severities");
exports.DEFAULT_OUT_OF_SCOPE = "Reporters will not receive a bounty for:\n\n* Any known issue, such as:\n  * Issues that are mentioned in any of the audit reports [LINK TO AUDIT REPORT].\n  * Vulnerabilities that were already made public (either by the project or by a third party)\n* Vulnerabilities that are exploited by the reporter themselves.\n* Attacks requiring access to leaked private keys or trusted addresses.\n* Issues that are not responsibly disclosed (issues should typically be reported through our platform).";
exports.DEFAULT_TOOLING_STEPS = {
    tooling: "hardhat",
    instructions: "### Usage\n\nInstallation:\n```\nnpm install\n```\n\nCreate `.env` files as needed. There is a file called `.env.example` that you can use as a template.\n\nRun the tests:\n```\nnpx hardhat test\n```",
};
exports.CODE_LANGUAGES = {
    solidity: ["solidity", "cairo", "go", "rust", "vyper", "simplicity"],
    other: ["javascript", "typescript", "python", "react"],
};
exports.COMMITTEE_CONTROLLED_SPLIT = 85;
exports.HATS_GOV_SPLIT = 10;
exports.HATS_REWARD_SPLIT = 5;
const createNewCommitteeMember = (owner, linkedMultisig) => ({
    name: "",
    address: owner !== null && owner !== void 0 ? owner : "",
    linkedMultisigAddress: linkedMultisig !== null && linkedMultisig !== void 0 ? linkedMultisig : "",
    "twitter-link": "",
    "image-ipfs-link": "",
    "pgp-keys": [{ publicKey: "" }],
});
exports.createNewCommitteeMember = createNewCommitteeMember;
const createNewCoveredContract = (sevIds) => {
    const severitiesIds = sevIds ? [...sevIds] : [];
    severitiesIds.sort();
    return {
        name: "",
        address: "",
        severities: severitiesIds,
        deploymentInfo: [{ contractAddress: "", chainId: "" }],
    };
};
exports.createNewCoveredContract = createNewCoveredContract;
const createNewVulnerabilitySeverity = (version) => {
    const editedVulnerabilitySeverityBase = {
        id: (0, uuid_1.v4)(),
        name: "",
        "contracts-covered": [],
        contractsCoveredNew: [],
        "nft-metadata": {
            name: "",
            description: "",
            animation_url: "",
            image: "",
            external_url: "",
        },
        description: "",
    };
    if (version === "v1") {
        return Object.assign(Object.assign({}, editedVulnerabilitySeverityBase), { index: 0 });
    }
    else {
        return Object.assign(Object.assign({}, editedVulnerabilitySeverityBase), { percentage: 0 });
    }
};
exports.createNewVulnerabilitySeverity = createNewVulnerabilitySeverity;
const getDefaultVaultParameters = (isAudit = false) => {
    return {
        fixedCommitteeControlledPercetange: exports.COMMITTEE_CONTROLLED_SPLIT,
        fixedHatsGovPercetange: exports.HATS_GOV_SPLIT,
        fixedHatsRewardPercetange: exports.HATS_REWARD_SPLIT,
        committeePercentage: isAudit ? 0 : 0,
        immediatePercentage: isAudit ? 100 : 40,
        vestedPercentage: isAudit ? 0 : 60,
        maxBountyPercentage: 90,
    };
};
exports.getDefaultVaultParameters = getDefaultVaultParameters;
const createNewVaultDescription = (version) => {
    const vulnerabilitySeveritiesTemplate = (0, severities_1.getVulnerabilitySeveritiesTemplate)(version);
    const severitiesIds = vulnerabilitySeveritiesTemplate.severities.map((s) => s.id);
    const severitiesOptionsForContractsCovered = vulnerabilitySeveritiesTemplate.severities.map((s) => ({
        label: s.name,
        value: s.id,
    }));
    return {
        version,
        "project-metadata": {
            name: "",
            icon: "",
            tokenIcon: "",
            website: "",
            type: undefined,
            emails: [{ address: "", status: "unverified" }],
        },
        scope: {
            reposInformation: [{ isMain: true, url: "", commitHash: "" }],
            description: "",
            codeLangs: [],
            docsLink: "",
            outOfScope: "",
        },
        committee: {
            chainId: "",
            "multisig-address": "",
            members: [],
        },
        "contracts-covered": [Object.assign({}, (0, exports.createNewCoveredContract)(severitiesIds))],
        "vulnerability-severities-spec": vulnerabilitySeveritiesTemplate,
        assets: [{ address: "", symbol: "" }],
        parameters: (0, exports.getDefaultVaultParameters)(),
        source: {
            name: "",
            url: "",
        },
        severitiesOptions: severitiesOptionsForContractsCovered,
        includesStartAndEndTime: false,
    };
};
exports.createNewVaultDescription = createNewVaultDescription;
function severitiesToContractsCoveredForm(severities) {
    let contractsForm = [];
    severities.forEach((severity) => {
        const contractsCovered = severity.contractsCoveredNew !== undefined ? severity.contractsCoveredNew : severity["contracts-covered"];
        if (contractsCovered && contractsCovered.length > 0) {
            contractsCovered.forEach((contractCovered) => {
                var _a;
                const address = (_a = contractCovered.link) !== null && _a !== void 0 ? _a : Object.values(contractCovered)[0];
                const contract = contractsForm.find((c) => c.address === address);
                const data = severity.contractsCoveredNew !== undefined
                    ? {
                        name: "",
                        address,
                        linesOfCode: contractCovered.linesOfCode,
                        deploymentInfo: contractCovered.deploymentInfo,
                    }
                    : Object.assign(Object.assign({}, (0, exports.createNewCoveredContract)()), { name: Object.keys(contractCovered)[0], address });
                if (contract) {
                    const contractIndex = contractsForm.indexOf(contract);
                    contractsForm[contractIndex] = Object.assign(Object.assign({}, data), { severities: [...contract.severities, severity.id] });
                }
                else {
                    contractsForm.push(Object.assign(Object.assign({}, data), { severities: [severity.id] }));
                }
            });
        }
        // else {
        //   contractsForm.push({
        //     ...createNewCoveredContract(),
        //     severities: [severity.id as string],
        //   });
        // }
    });
    return contractsForm;
}
exports.severitiesToContractsCoveredForm = severitiesToContractsCoveredForm;
function descriptionToEditedForm(vaultDescription, withDefaultData = false) {
    var _a, _b, _c;
    const severitiesWithIds = vaultDescription.severities.map((sev) => (Object.assign(Object.assign({}, sev), { id: (0, uuid_1.v4)() })));
    const severitiesOptions = severitiesWithIds.map((s) => ({
        label: s.name,
        value: s.id,
    }));
    const defaultDescription = (0, exports.createNewVaultDescription)("v1");
    const baseEditedDescription = Object.assign(Object.assign({}, vaultDescription), { committee: Object.assign(Object.assign({}, vaultDescription.committee), { members: vaultDescription.committee.members
                ? withDefaultData
                    ? vaultDescription.committee.members.map((m) => (Object.assign(Object.assign({}, m), { "pgp-keys": m["pgp-keys"] ? m["pgp-keys"] : [{ publicKey: "" }] })))
                    : vaultDescription.committee.members
                : [(0, exports.createNewCommitteeMember)()] }), "project-metadata": Object.assign(Object.assign({}, vaultDescription["project-metadata"]), { emails: withDefaultData ? [{ address: "", status: "unverified" }] : [] }), "contracts-covered": severitiesToContractsCoveredForm(severitiesWithIds), assets: defaultDescription.assets, parameters: defaultDescription.parameters, severitiesOptions, includesStartAndEndTime: !!vaultDescription["project-metadata"].starttime || !!vaultDescription["project-metadata"].endtime });
    // If we are creating a editSession from a descriptionHash, we add all the pgpKeys in the old
    // format, to the new format (on the first member)
    if (withDefaultData) {
        const existingPgpKeyOrKeys = (_b = (_a = vaultDescription["communication-channel"]) === null || _a === void 0 ? void 0 : _a["pgp-pk"]) !== null && _b !== void 0 ? _b : [];
        let existingPgpKeys = typeof existingPgpKeyOrKeys === "string" ? [existingPgpKeyOrKeys] : existingPgpKeyOrKeys;
        existingPgpKeys = [...existingPgpKeys, ...baseEditedDescription.committee.members[0]["pgp-keys"].map((key) => key.publicKey)];
        existingPgpKeys = [...new Set(existingPgpKeys)];
        baseEditedDescription.committee.members[0]["pgp-keys"] = (_c = existingPgpKeys === null || existingPgpKeys === void 0 ? void 0 : existingPgpKeys.map((key) => ({ publicKey: key }))) !== null && _c !== void 0 ? _c : [
            { publicKey: "" },
        ];
    }
    // V1 vaults
    if (vaultDescription.version === "v1" || !vaultDescription.version) {
        return Object.assign(Object.assign({}, baseEditedDescription), { version: "v1", "vulnerability-severities-spec": {
                severities: severitiesWithIds,
                name: "",
                indexArray: vaultDescription.indexArray,
            } });
    }
    // V2 vaults
    return Object.assign(Object.assign({}, baseEditedDescription), { version: "v2", "vulnerability-severities-spec": {
            severities: severitiesWithIds,
            name: "",
        } });
}
exports.descriptionToEditedForm = descriptionToEditedForm;
function editedSeveritiesToSeverities(severities, contractsCovered) {
    return severities.map((severity) => {
        const newSeverity = Object.assign({}, severity);
        const severityId = newSeverity.id;
        if (newSeverity.id)
            delete newSeverity.id;
        return Object.assign(Object.assign({}, newSeverity), { 
            // "contracts-covered": contractsCovered
            //   .filter((contract) => contract.severities?.includes(severityId))
            //   .map((contract) => ({ [contract.name]: contract.address })),
            contractsCoveredNew: contractsCovered
                .filter((contract) => { var _a; return (_a = contract.severities) === null || _a === void 0 ? void 0 : _a.includes(severityId); })
                .map((contract) => ({
                link: contract.address,
                linesOfCode: contract.linesOfCode,
                deploymentInfo: contract.deploymentInfo,
            })) });
    });
}
function editedSeveritiesToSeveritiesv2(severities, contractsCovered) {
    return severities.map((severity) => {
        const newSeverity = Object.assign({}, severity);
        const severityId = newSeverity.id;
        return Object.assign(Object.assign({}, newSeverity), { 
            // "contracts-covered": contractsCovered
            //   .filter((contract) => contract.severities?.includes(severityId))
            //   .map((contract) => ({ [contract.name]: contract.address })),
            contractsCoveredNew: contractsCovered
                .filter((contract) => { var _a; return (_a = contract.severities) === null || _a === void 0 ? void 0 : _a.includes(severityId); })
                .map((contract) => ({
                link: contract.address,
                linesOfCode: contract.linesOfCode,
                deploymentInfo: contract.deploymentInfo,
            })) });
    });
}
function editedFormToDescription(editedVaultDescription) {
    // remove emails
    const _a = editedVaultDescription["project-metadata"], { emails } = _a, projectMetadata = __rest(_a, ["emails"]);
    if (editedVaultDescription.version === "v1") {
        return {
            version: editedVaultDescription.version,
            "project-metadata": projectMetadata,
            "communication-channel": editedVaultDescription["communication-channel"],
            committee: editedVaultDescription.committee,
            source: editedVaultDescription.source,
            severities: editedSeveritiesToSeverities(editedVaultDescription["vulnerability-severities-spec"].severities, editedVaultDescription["contracts-covered"]),
            scope: editedVaultDescription.scope,
        };
    }
    else {
        return {
            version: editedVaultDescription.version,
            "project-metadata": projectMetadata,
            "communication-channel": editedVaultDescription["communication-channel"],
            committee: editedVaultDescription.committee,
            source: editedVaultDescription.source,
            severities: editedSeveritiesToSeveritiesv2(editedVaultDescription["vulnerability-severities-spec"].severities, editedVaultDescription["contracts-covered"]),
            scope: editedVaultDescription.scope,
        };
    }
}
exports.editedFormToDescription = editedFormToDescription;
function fixObject(description) {
    if ("Project-metadata" in description) {
        description["project-metadata"] = description["Project-metadata"];
        delete description["Project-metadata"];
    }
    if ("gamification" in description["project-metadata"] && description["project-metadata"].gamification) {
        description["project-metadata"].type = "gamification";
    }
    if (!description["project-metadata"].type || description["project-metadata"].type === "") {
        description["project-metadata"].type = "normal";
    }
    if (description["project-metadata"].type === "Grants") {
        description["project-metadata"].type = "grants";
    }
    if (description["project-metadata"].type === "Audit competition") {
        description["project-metadata"].type = "audit";
    }
    return description;
}
exports.fixObject = fixObject;
function editedFormToCreateVaultOnChainCall(editedVaultDescription, descriptionHash, rewardController) {
    var _a;
    const convertStringToSlug = (str) => str
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    const formatPercentage = (percentage) => Number(percentage) * 100;
    const { maxBountyPercentage, immediatePercentage, vestedPercentage, committeePercentage } = editedVaultDescription.parameters;
    return {
        chainId: +((_a = editedVaultDescription.committee.chainId) !== null && _a !== void 0 ? _a : "1"),
        asset: editedVaultDescription.assets[0].address,
        name: convertStringToSlug(editedVaultDescription["project-metadata"].name),
        symbol: convertStringToSlug(editedVaultDescription["project-metadata"].name),
        committee: editedVaultDescription.committee["multisig-address"],
        owner: editedVaultDescription.committee["multisig-address"],
        rewardController: rewardController !== null && rewardController !== void 0 ? rewardController : "0x0000000000000000000000000000000000000000",
        maxBounty: formatPercentage(maxBountyPercentage),
        bountySplit: {
            committee: formatPercentage(committeePercentage),
            hacker: formatPercentage(immediatePercentage),
            hackerVested: formatPercentage(vestedPercentage),
        },
        vestingDuration: 2592000,
        vestingPeriods: 30,
        isPaused: false,
        descriptionHash,
    };
}
exports.editedFormToCreateVaultOnChainCall = editedFormToCreateVaultOnChainCall;
