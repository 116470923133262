"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HATPaymentSplitter_abi = void 0;
exports.HATPaymentSplitter_abi = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "contract IERC20Upgradeable", name: "token", type: "address" },
            { indexed: false, internalType: "address", name: "to", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "ERC20PaymentReleased",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "address", name: "account", type: "address" },
            { indexed: false, internalType: "uint256", name: "shares", type: "uint256" },
        ],
        name: "PayeeAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "address", name: "from", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "PaymentReceived",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "address", name: "to", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "PaymentReleased",
        type: "event",
    },
    {
        inputs: [
            { internalType: "address[]", name: "_payees", type: "address[]" },
            { internalType: "uint256[]", name: "_shares", type: "uint256[]" },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "payee",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "releasable",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IERC20Upgradeable", name: "token", type: "address" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "releasable",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address payable", name: "account", type: "address" }],
        name: "release",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IERC20Upgradeable", name: "token", type: "address" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "release",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "contract ITokenLock", name: "_tokenLock", type: "address" }],
        name: "releaseFromTokenLock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IERC20Upgradeable", name: "token", type: "address" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "released",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "released",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "shares",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract ITokenLock", name: "_tokenLock", type: "address" },
            { internalType: "contract IERC20", name: "_token", type: "address" },
        ],
        name: "sweepTokenFromTokenLock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "contract IERC20Upgradeable", name: "token", type: "address" }],
        name: "totalReleased",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalReleased",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalShares",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract ITokenLock", name: "_tokenLock", type: "address" },
            { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "withdrawSurplusFromTokenLock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { stateMutability: "payable", type: "receive" },
];
