"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payoutStatusInfo = exports.PayoutStatus = void 0;
var PayoutStatus;
(function (PayoutStatus) {
    PayoutStatus["Creating"] = "creating";
    PayoutStatus["Pending"] = "pending";
    PayoutStatus["ReadyToExecute"] = "readyToExecute";
    PayoutStatus["Executed"] = "executed";
    PayoutStatus["Approved"] = "approved";
    PayoutStatus["Rejected"] = "rejected";
})(PayoutStatus = exports.PayoutStatus || (exports.PayoutStatus = {}));
exports.payoutStatusInfo = {
    [PayoutStatus.Creating]: {
        label: "creating",
        color: "--warning-yellow",
    },
    [PayoutStatus.Pending]: {
        label: "collecting",
        color: "--warning-yellow",
    },
    [PayoutStatus.ReadyToExecute]: {
        label: "waitingExecution",
        color: "--secondary",
    },
    [PayoutStatus.Executed]: {
        label: "waitingApproval",
        color: "--warning-yellow",
    },
    [PayoutStatus.Approved]: {
        label: "approved",
        color: "--secondary-light",
    },
    [PayoutStatus.Rejected]: {
        label: "rejected",
        color: "--error-red",
    },
};
