import styled from "styled-components";

export const StyledContactInfo = styled.div`
  color: var(--white);

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
