import styled from "styled-components";
import { getSpacing } from "styles";

export const StyledVulnerabilitySeveritiesList = styled.div`
  .severities-pills {
    display: flex;
    flex-wrap: wrap;
    gap: ${getSpacing(1)};
  }

  .bountyCalculationExample {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: ${getSpacing(6)};
    color: var(--white);

    .input {
      width: 120px;

      &:nth-of-type(3) {
        width: 90px;
      }

      p {
        font-size: var(--tiny);
      }
    }

    span.operator {
      margin: 0 ${getSpacing(1)};
      margin-bottom: ${getSpacing(0.6)};
    }
  }

  .helper {
    color: var(--white);
    font-weight: 700;
    margin-bottom: ${getSpacing(1)};
  }

  .maxBounty {
    width: 40%;
    margin-bottom: ${getSpacing(6)};
  }
`;
