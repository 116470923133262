"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HATVaultsNFT_abi = void 0;
exports.HATVaultsNFT_abi = [
    {
        inputs: [
            { internalType: "string", name: "_merkleTreeIPFSRef", type: "string" },
            { internalType: "bytes32", name: "_root", type: "bytes32" },
            { internalType: "uint256", name: "_deadline", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "account", type: "address" },
            { indexed: true, internalType: "address", name: "operator", type: "address" },
            { indexed: false, internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "string", name: "merkleTreeIPFSRef", type: "string" },
            { indexed: false, internalType: "bytes32", name: "root", type: "bytes32" },
            { indexed: false, internalType: "uint256", name: "deadline", type: "uint256" },
        ],
        name: "MerkleTreeChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
            { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "operator", type: "address" },
            { indexed: true, internalType: "address", name: "from", type: "address" },
            { indexed: true, internalType: "address", name: "to", type: "address" },
            { indexed: false, internalType: "uint256[]", name: "ids", type: "uint256[]" },
            { indexed: false, internalType: "uint256[]", name: "values", type: "uint256[]" },
        ],
        name: "TransferBatch",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "operator", type: "address" },
            { indexed: true, internalType: "address", name: "from", type: "address" },
            { indexed: true, internalType: "address", name: "to", type: "address" },
            { indexed: false, internalType: "uint256", name: "id", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
        ],
        name: "TransferSingle",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "string", name: "value", type: "string" },
            { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
        ],
        name: "URI",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "hatVaults", type: "address" },
            { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
        ],
        name: "VaultPaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "hatVaults", type: "address" },
            { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
        ],
        name: "VaultResumed",
        type: "event",
    },
    {
        inputs: [],
        name: "HUNDRED_PERCENT",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "TIERS",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "string", name: "_uri", type: "string" },
        ],
        name: "addVault",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "account", type: "address" },
            { internalType: "uint256", name: "id", type: "uint256" },
        ],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address[]", name: "accounts", type: "address[]" },
            { internalType: "uint256[]", name: "ids", type: "uint256[]" },
        ],
        name: "balanceOfBatch",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "deadline",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "getTierFromShares",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "getTiersToRedeemFromShares",
        outputs: [{ internalType: "bool[3]", name: "tiers", type: "bool[3]" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "uint8", name: "tier", type: "uint8" },
        ],
        name: "getTokenId",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
        ],
        name: "getVaultId",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "account", type: "address" },
            { internalType: "address", name: "operator", type: "address" },
        ],
        name: "isApprovedForAll",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "isEligible",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
        ],
        name: "pauseVault",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        name: "pausedVaults",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address[]", name: "hatVaults", type: "address[]" },
            { internalType: "uint256[]", name: "pids", type: "uint256[]" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "redeemMultipleFromShares",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address[]", name: "hatVaults", type: "address[]" },
            { internalType: "uint256[]", name: "pids", type: "uint256[]" },
            { internalType: "address", name: "account", type: "address" },
            { internalType: "uint8[]", name: "tiers", type: "uint8[]" },
            { internalType: "bytes32[][]", name: "proofs", type: "bytes32[][]" },
        ],
        name: "redeemMultipleFromTree",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "address", name: "account", type: "address" },
        ],
        name: "redeemSingleFromShares",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
            { internalType: "address", name: "account", type: "address" },
            { internalType: "uint8", name: "tier", type: "uint8" },
            { internalType: "bytes32[]", name: "proof", type: "bytes32[]" },
        ],
        name: "redeemSingleFromTree",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [
            { internalType: "address", name: "hatVaults", type: "address" },
            { internalType: "uint256", name: "pid", type: "uint256" },
        ],
        name: "resumeVault",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "root",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256[]", name: "ids", type: "uint256[]" },
            { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
            { internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "safeBatchTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
        name: "supportsInterface",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "address", name: "", type: "address" },
        ],
        name: "tokensRedeemed",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupplyCounter",
        outputs: [{ internalType: "uint256", name: "_value", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "string", name: "_merkleTreeIPFSRef", type: "string" },
            { internalType: "bytes32", name: "_root", type: "bytes32" },
            { internalType: "uint256", name: "_deadline", type: "uint256" },
        ],
        name: "updateTree",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "uri",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "uris",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        name: "vaultsRegistered",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
];
