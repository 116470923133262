import { createNewVulnerabilitySeverity } from "@hats-finance/shared";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormInput, Pill } from "components";
import { useEnhancedFormContext } from "hooks/form/useEnhancedFormContext";
import { useContext, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IEditedVaultDescription } from "types";
import { VaultEditorFormContext } from "../../store";
import VulnerabilitySeverityForm from "./VulnerabilitySeverityForm/VulnerabilitySeverityForm";
import { StyledVulnerabilitySeveritiesList } from "./styles";

export function VulnerabilitySeveritiesList() {
  const { t } = useTranslation();
  const { control, register } = useEnhancedFormContext<IEditedVaultDescription>();
  const { fields, append, remove } = useFieldArray({ control, name: "vulnerability-severities-spec.severities" });
  const watchFieldArray = useWatch({ control, name: `vulnerability-severities-spec.severities`, defaultValue: [] });
  const severities = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [editSeverities, setEditSeverities] = useState(false);

  const isV1 = useWatch({ control, name: "version" }) === "v1";

  const { allFormDisabled } = useContext(VaultEditorFormContext);

  return (
    <StyledVulnerabilitySeveritiesList>
      <div className="mb-4" dangerouslySetInnerHTML={{ __html: t("vaultEditorDefaultSeveritiesExplanation") }} />

      <div className="severities-pills mb-5">
        {severities.map((severity, index) => (
          <Pill key={index} isChecked text={severity.name.toLowerCase().replace("severity", "").trim()} />
        ))}
      </div>

      <FormInput
        name="editSeverities"
        checked={editSeverities}
        type="toggle"
        onChange={(e) => setEditSeverities(e.target.checked)}
        label={t("editSeverities")}
        colorable
      />

      {editSeverities && (
        <>
          <div className="helper-text" dangerouslySetInnerHTML={{ __html: t("vaultEditorSeveritiesExplanation") }} />

          {/* <p className="helper">{t("setMaxBountyHelper")}:</p>
          <div className="maxBounty">
            <FormInput
              {...register(`parameters.maxBountyPercentage`)}
              disabled={isEditingExistingVault || allFormDisabled}
              type="whole-number"
              label={t("VaultEditor.vault-parameters.maxBountyPercentage")}
              placeholder={t("VaultEditor.vault-parameters.maxBountyPercentage-placeholder")}
              colorable
            />
          </div> */}

          {isV1 && (
            <div>
              <FormInput
                {...register(`vulnerability-severities-spec.indexArray`)}
                disabled={allFormDisabled}
                label={t("VaultEditor.severities-index-array")}
                colorable
                placeholder={t("VaultEditor.severities-index-array-placeholder")}
              />
            </div>
          )}

          {severities.map((severity, index) => (
            <VulnerabilitySeverityForm key={severity.id} index={index} remove={remove} severitiesCount={severities.length} />
          ))}

          {!allFormDisabled && (
            <Button styleType="invisible" onClick={() => append(createNewVulnerabilitySeverity(isV1 ? "v1" : "v2"))}>
              <AddIcon className="mr-1" />
              <span>{t("addSeverity")}</span>
            </Button>
          )}
        </>
      )}
    </StyledVulnerabilitySeveritiesList>
  );
}
