"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressSafes = exports.isAddressAMultisigMember = exports.getGnosisSafeInfo = exports.isAGnosisSafeTx = exports.getSafeHomeLink = exports.getSafeDashboardLink = exports.getSafeWalletConnectLink = exports.getGnosisSafeTxServiceBaseUrl = exports.getGnosisChainPrefixByChainId = exports.getGnosisChainNameByChainId = void 0;
const chains_1 = require("@wagmi/chains");
const axios_1 = __importStar(require("axios"));
const ethers_1 = require("ethers");
const config_1 = require("../config");
const general_utils_1 = require("./general.utils");
const getGnosisChainNameByChainId = (chainId) => {
    switch (chainId) {
        case chains_1.mainnet.id:
            return "mainnet";
        case chains_1.goerli.id:
            return "goerli";
        case chains_1.arbitrum.id:
            return "arbitrum";
        case chains_1.optimism.id:
            return "optimism";
        case chains_1.polygon.id:
            return "polygon";
        case chains_1.avalanche.id:
            return "avalanche";
        case chains_1.bsc.id:
            return "bsc";
        default:
            throw new Error(`Gnosis doesn't support chainId:${chainId} yet`);
    }
};
exports.getGnosisChainNameByChainId = getGnosisChainNameByChainId;
const getGnosisChainPrefixByChainId = (chainId) => {
    switch (chainId) {
        case chains_1.mainnet.id:
            return "eth";
        case chains_1.goerli.id:
            return "gor";
        case chains_1.arbitrum.id:
            return "arb1";
        case chains_1.optimism.id:
            return "oeth";
        case chains_1.polygon.id:
            return "matic";
        case chains_1.avalanche.id:
            return "avax";
        case chains_1.bsc.id:
            return "bnb";
        default:
            throw new Error(`Gnosis doesn't support chainId:${chainId} yet`);
    }
};
exports.getGnosisChainPrefixByChainId = getGnosisChainPrefixByChainId;
const getGnosisSafeTxServiceBaseUrl = (chainId) => {
    if (chainId === config_1.meter.id)
        return `https://safe-gateway.meter.io/txs`;
    return `https://safe-transaction-${(0, exports.getGnosisChainNameByChainId)(chainId)}.safe.global`;
};
exports.getGnosisSafeTxServiceBaseUrl = getGnosisSafeTxServiceBaseUrl;
const getSafeWalletConnectLink = (address, chainId) => {
    const safeDashboardLink = (0, exports.getSafeDashboardLink)(address, chainId);
    if (!safeDashboardLink)
        return undefined;
    return `${safeDashboardLink}&appUrl=https%3A%2F%2Fapps-portal.safe.global%2Fwallet-connect`;
};
exports.getSafeWalletConnectLink = getSafeWalletConnectLink;
const getSafeDashboardLink = (address, chainId) => {
    try {
        if (!chainId)
            return "";
        const checksummedSafeAddress = ethers_1.utils.getAddress(address);
        return `https://app.safe.global/apps/open?safe=${(0, exports.getGnosisChainPrefixByChainId)(chainId)}:${checksummedSafeAddress}`;
    }
    catch (error) {
        return undefined;
    }
};
exports.getSafeDashboardLink = getSafeDashboardLink;
const getSafeHomeLink = (address, chainId) => {
    try {
        if (!chainId)
            return "";
        const checksummedSafeAddress = ethers_1.utils.getAddress(address);
        return `https://app.safe.global/home?safe=${(0, exports.getGnosisChainPrefixByChainId)(chainId)}:${checksummedSafeAddress}`;
    }
    catch (error) {
        return undefined;
    }
};
exports.getSafeHomeLink = getSafeHomeLink;
const getGnosisTxsApiEndpoint = (txHash, chainId) => {
    try {
        if (!chainId)
            return "";
        return `${(0, exports.getGnosisSafeTxServiceBaseUrl)(chainId)}/api/v1/multisig-transactions/${txHash}`;
    }
    catch (error) {
        return undefined;
    }
};
const getGnosisSafeStatusApiEndpoint = (safeAddress, chainId) => {
    try {
        if (!chainId)
            return "";
        const checksummedSafeAddress = ethers_1.utils.getAddress(safeAddress);
        return `${(0, exports.getGnosisSafeTxServiceBaseUrl)(chainId)}/api/v1/safes/${checksummedSafeAddress}`;
    }
    catch (error) {
        return undefined;
    }
};
const getAddressSafesApiEndpoint = (walletAddress, chainId) => {
    try {
        if (!chainId)
            return "";
        return `${(0, exports.getGnosisSafeTxServiceBaseUrl)(chainId)}/api/v1/owners/${walletAddress}/safes`;
    }
    catch (error) {
        return undefined;
    }
};
const isAGnosisSafeTx = async (tx, chainId) => {
    var _a;
    try {
        if (!chainId)
            throw new Error("Please provide chainId");
        const safeUrl = getGnosisTxsApiEndpoint(tx, chainId);
        if (!safeUrl)
            throw new Error("No url");
        const res = await axios_1.default.get(safeUrl);
        return !!((_a = res.data) === null || _a === void 0 ? void 0 : _a.safeTxHash);
    }
    catch (error) {
        return false;
    }
};
exports.isAGnosisSafeTx = isAGnosisSafeTx;
const getGnosisSafeInfo = async (address, chainId) => {
    var _a, _b, _c;
    try {
        if (!chainId || !address)
            throw new Error("Please provide address and chainId");
        const safeInfoStorage = (0, general_utils_1.isServer)() ? null : JSON.parse((_a = sessionStorage.getItem(`safeInfo-${chainId}-${address}`)) !== null && _a !== void 0 ? _a : "null");
        const safeUrl = getGnosisSafeStatusApiEndpoint(address, chainId);
        if (!safeUrl)
            throw new Error("No url");
        const data = safeInfoStorage !== null && safeInfoStorage !== void 0 ? safeInfoStorage : (await axios_1.default.get(safeUrl)).data;
        !(0, general_utils_1.isServer)() && sessionStorage.setItem(`safeInfo-${chainId}-${address}`, JSON.stringify(data));
        if (!data)
            throw new Error("No data");
        return {
            isSafeAddress: (_b = data.isSafeAddress) !== null && _b !== void 0 ? _b : true,
            owners: data.owners,
            threshold: data.threshold,
        };
    }
    catch (error) {
        const defaultData = {
            isSafeAddress: false,
            owners: [],
            threshold: 0,
        };
        if (error instanceof axios_1.AxiosError && ((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 404) {
            !(0, general_utils_1.isServer)() && sessionStorage.setItem(`safeInfo-${chainId}-${address}`, JSON.stringify(defaultData));
            return defaultData;
        }
        return defaultData;
    }
};
exports.getGnosisSafeInfo = getGnosisSafeInfo;
const isAddressAMultisigMember = async (multisigAddress, address, chainId) => {
    if (!multisigAddress || !chainId || !address)
        return false;
    const members = (await (0, exports.getGnosisSafeInfo)(multisigAddress, Number(chainId))).owners;
    const isMember = members.includes(address);
    return isMember;
};
exports.isAddressAMultisigMember = isAddressAMultisigMember;
const getAddressSafes = async (walletAddress, chainId) => {
    var _a, _b;
    try {
        if (!chainId)
            throw new Error("Please provide chainId");
        const addressSafesStorage = (0, general_utils_1.isServer)()
            ? null
            : JSON.parse((_a = sessionStorage.getItem(`addressSafes-${chainId}-${walletAddress}`)) !== null && _a !== void 0 ? _a : "null");
        const safeUrl = getAddressSafesApiEndpoint(walletAddress, chainId);
        if (!safeUrl)
            throw new Error("No url");
        const data = addressSafesStorage !== null && addressSafesStorage !== void 0 ? addressSafesStorage : (await axios_1.default.get(safeUrl)).data;
        !(0, general_utils_1.isServer)() && sessionStorage.setItem(`addressSafes-${chainId}-${walletAddress}`, JSON.stringify(data));
        if (!data)
            throw new Error("No data");
        return (_b = data.safes) !== null && _b !== void 0 ? _b : [];
    }
    catch (error) {
        const defaultData = { safes: [] };
        !(0, general_utils_1.isServer)() && sessionStorage.setItem(`addressSafes-${chainId}-${walletAddress}`, JSON.stringify(defaultData));
        return defaultData.safes;
    }
};
exports.getAddressSafes = getAddressSafes;
